import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	accordion: {
		alignItems: 'baseline !important',
		border: 'none !important',
		zIndex: '1',
		backgroundColor: '#fff !important',
		borderTop: '1px solid #EEF2F6 !important',
		borderRadius: 'none !important',
		'&.Mui-expanded': {
			background: ' #F8FAFC !important',
			zIndex: '10',
			'&>div': {
				background: ' #F8FAFC !important',
			},
			'& #accordionSummary': {
				background: ' #F8FAFC !important',
			},
		},
	},
	accordionInner: {
		// position: 'sticky',
		top: '0',
		// zIndex: '101',
		display: 'flex',
		padding: '0 24px',
	},
	accordionSummary: {
		// @ts-ignore
		position: 'sticky !important',
		padding: '16px 0 !important',
		width: '100%',

		top: '0 !important', // @ts-ignore
		userSelect: 'auto !important',
		backgroundColor: '#ffffff !important',
		cursor: 'default !important',
		'& .MuiAccordionSummary-content': {
			margin: '0 !important',
		},
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline !important',
		transition: 'all 0.1s !important',
		'& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
			margin: '25px !important',
			position: 'relative',
			fill: '#818D9F',
			transform: 'rotate(90deg)',
		},
		'&.Mui-disabled': {
			opacity: '1 !important',
		},
	},
	summaryInfo: {
		display: 'flex',
		width: '48%',
	},
	summaryNumber: {
		minWidth: '48px',
		marginTop: '10px',
	},
	summaryInfoIndex: {
		width: '10%',
		display: 'flex',
		alignItems: 'flex-start',
	},
	summaryScore: {
		width: '25%',
		display: 'flex',
		alignItems: 'flex-start',
	},
	summaryInfoClient: {
		width: '35%',
		paddingRight: '8px',
		userSelect: 'text',
		cursor: 'text',
	},
	summaryInfoEmployee: {
		width: '32%',
		paddingRight: '8px',
		userSelect: 'text',
		cursor: 'text',
	},
	summaryTags: {
		width: '52%',
	},

	darkText: {
		color: '#2F3747 !important',
		fontSize: '14px !important',
		fontWeight: '600 !important',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
	},
	lightText: {
		color: '#738094 !important',
		fontSize: '14px !important',
	},
	lignthTextSmall: {
		color: '#738094 !important',
		fontSize: '12px !important',
		lineHeight: '20px',
	},
	numberText: {
		color: '#2F3747 !important',
	},
	accordionDetails: {
		backgroundColor: '#F8FAFC !important',
		border: 'none !important',
		margin: '0px !important',
		padding: '0 24px 24px 24px !important',
	},
	expandIcon: {
		display: 'flex',
		borderRadius: '5px',
		width: '32px',
		height: '32px',
		backgroundColor: '#F8FAFC',
		border: '1px solid #E3E8EF',
		cursor: 'pointer',
		justifyContent: 'center',
		paddingTop: '6px',
		marginTop: '16px',
	},
});
