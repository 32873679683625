import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { gptAPI } from 'services/gptService';
import { GPTAdditionalSettings, GptEngine, GPTOptionParams, IGPTRule, IGPTRuleExtended } from './types';
import { createNullArray } from '../markupRules/actions';
import { defaultEngine } from './constants';

interface gptState {
	gptRules: (IGPTRule | null)[];
	gptRulesLoading: boolean;
	currentRule: IGPTRuleExtended | null;
	isNewRule: boolean;
	gptEngines: GptEngine[];
	gptEnginesLoading: boolean;
	gptModels: string[];
	currentGptEngine: GptEngine;
	defaultGptModel: string;
	gptModelsLoading: boolean;
	currentRuleLoading: boolean;
	gptAdditionalParams: Record<string, GPTOptionParams>;
	showGptContent: boolean;
	addCallTime: boolean;
	addFragmentTime: boolean;
	addNowTime: boolean;
	gptRulesForCopy: (IGPTRule | null)[];
	isFree?: boolean;
}

const initialState: gptState = {
	gptRules: createNullArray(5),
	gptRulesLoading: true,
	currentRule: null,
	isNewRule: false,
	gptEngines: [],
	gptEnginesLoading: false,
	gptModels: [],
	currentGptEngine: defaultEngine,
	defaultGptModel: '',
	gptModelsLoading: false,
	currentRuleLoading: false,
	gptAdditionalParams: {},
	showGptContent: true,
	addCallTime: false,
	addFragmentTime: false,
	isFree: false,
	addNowTime: false,
	gptRulesForCopy: createNullArray(5),
};

const baseSettings = {
	engine: defaultEngine,
	model: '',
};

export const defaultGPTSettings: GPTAdditionalSettings = {
	...baseSettings,
	assistantText: '',
	addCallTime: false,
	addFragmentTime: false,
	addNowTime: false,
	isFree: false,
};

const newGptRule: IGPTRuleExtended = {
	...baseSettings,
	title: '',
	simpleQuestions: [],
};

export const gptSlice = createSlice({
	name: 'chatGPT',
	initialState,
	reducers: {
		setNewRule(state, action: PayloadAction<Partial<IGPTRuleExtended> | null>) {
			state.currentRule = action.payload ? { ...newGptRule, ...action.payload } : newGptRule;
			state.isNewRule = true;
		},
		removeNewRuleState(state) {
			state.isNewRule = false;
		},
		resetCurrentRule(state) {
			state.currentRule = null;
		},
		resetAllGpt(state) {
			state.gptRules = createNullArray(5);
			state.gptRulesLoading = true;
			state.currentRule = null;
			state.isNewRule = false;
			state.gptEngines = [];
			state.gptEnginesLoading = false;
			state.gptModels = [];
			state.currentGptEngine = defaultEngine;
			state.defaultGptModel = '';
			state.gptModelsLoading = false;
			state.currentRuleLoading = false;
			state.gptAdditionalParams = {};
		},
		setShowGptContent(state, action: PayloadAction<boolean>) {
			state.showGptContent = action.payload;
		},
		setGPTRulesForCopy(state, action: PayloadAction<IGPTRule[]>) {
			state.gptRulesForCopy = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(gptAPI.endpoints.getGPTRules.matchPending, (state) => {
			state.gptRulesLoading = true;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRules.matchFulfilled, (state, { payload }) => {
			state.gptRules = payload;
			state.gptRulesLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRules.matchRejected, (state) => {
			state.gptRulesLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRuleById.matchFulfilled, (state, { payload }) => {
			state.currentRule = payload;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTEngines.matchPending, (state) => {
			state.gptEnginesLoading = true;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTEngines.matchFulfilled, (state, { payload }) => {
			state.gptEngines = payload;
			state.gptEnginesLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTEngines.matchRejected, (state) => {
			state.gptEnginesLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTOptions.matchPending, (state) => {
			state.gptModelsLoading = true;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTOptions.matchFulfilled, (state, { payload }) => {
			const { models, defaultModel: defaultGptModel, additionalParams, currentGptEngine } = payload;
			state.gptModels = models;
			state.defaultGptModel = defaultGptModel;
			state.currentGptEngine = currentGptEngine;
			state.gptAdditionalParams = additionalParams;
			state.gptModelsLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTOptions.matchRejected, (state) => {
			state.gptModelsLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRuleById.matchPending, (state) => {
			state.currentRuleLoading = true;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRuleById.matchFulfilled, (state) => {
			state.currentRuleLoading = false;
		});
		builder.addMatcher(gptAPI.endpoints.getGPTRuleById.matchRejected, (state) => {
			state.currentRuleLoading = false;
		});
	},
});

export default gptSlice.reducer;
