import { FC, useEffect, useState } from 'react';
import s from './styles.module.scss';
import { TextButtonWithTooltip } from 'shared/ui/index';
import { translate } from 'localizations';

interface CallBodyEnginesProps {
	allEngines: false | string[] | null;
	engine: string | false | null;
	handleClick: (engine: string) => void;
	loading: boolean;
	lang: string;
}

const CallBodyEngines: FC<CallBodyEnginesProps> = ({ allEngines, engine, handleClick, loading, lang }) => {
	const [activeEngine, setActiveEngine] = useState<string | null>(null);
	const handleClickEngine = (engineItem: string) => {
		setActiveEngine(engineItem);
		handleClick(engineItem);
	};

	useEffect(() => {
		if (engine) setActiveEngine(engine);
	}, [engine]);

	return (
		<>
			{allEngines && allEngines.length ? (
				<div className={s.blockEnginesFlex}>
					<div className={s.titleStt}>{translate('textRecognition', lang)}</div>
					{allEngines && allEngines.length && (
						<div className={s.blockEngines}>
							{allEngines.map((engineItem) => {
								return (
									<TextButtonWithTooltip
										text={engineItem}
										className={s.engine}
										loading={activeEngine === engineItem ? loading : false}
										active={activeEngine === engineItem}
										background="snowWhite"
										handleClick={
											engineItem !== engine ? () => handleClickEngine(engineItem) : undefined
										}
										tooltip={engineItem === engine ? translate('engineActive', lang) : null}
									/>
								);
							})}
						</div>
					)}
				</div>
			) : null}
		</>
	);
};

export default CallBodyEngines;
