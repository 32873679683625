import { SttValuesForRequest } from 'widgets/STT/namespaces';

type SetParametersArgs = {
	action: string;
	filterHash?: string | null;
	stt?: SttValuesForRequest;
	columns?: string[];
	callIds?: string[];
	id?: string;
	engine?: string | boolean | undefined;
};

export const useParametersForAction = () => {
	const setParameters = ({ action, filterHash, stt = {}, columns, callIds }: SetParametersArgs) => {
		const baseParams = {
			action,
			columns: columns || [],
			...(Object.keys(stt).length > 0 && stt),
		};

		if (filterHash) {
			return {
				...baseParams,
				search_filter_hash: filterHash,
			};
		}

		return {
			...baseParams,
			call_ids: callIds,
		};
	};

	const setParametersSingleCall = ({ action, stt = {}, id, columns, engine }: SetParametersArgs) => {
		return {
			id,
			data: {
				action,
				columns,
				...(Object.keys(stt).length > 0 && stt),
				engine,
			},
		};
	};

	return { setParameters, setParametersSingleCall };
};
