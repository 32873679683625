import { CircularPreloader, CustomCheckbox } from 'components/common';
import { translate } from 'localizations';
import { RouteParams } from 'pages/Settings/types';
import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExportParams } from 'store/calls/calls.types';
import { UniversalButton } from 'shared/ui';

import cls from './styles.module.scss';
import { SttValuesForRequest } from 'widgets/STT/namespaces';

interface ExportModalInnerProps {
	toggleSttExportModal: () => void;
	tags: ExportParams | null;
	onIconClick: (
		action: string,
		columns: string[] | undefined,
		stt?: SttValuesForRequest,
		engine?: string | null | undefined | boolean,
	) => Promise<void>;
	actionName: string;
	engine?: string | null | boolean;
}

const ExportModalInner: FC<ExportModalInnerProps> = (props) => {
	const { toggleSttExportModal, tags, onIconClick, actionName, engine } = props;
	const { lang } = useParams<RouteParams>();

	const [selectedTags, setSelectedTags] = useState<{ [key: string]: string[] }>({
		tags_without_values: [],
		tags_with_values: [],
		other_params: [],
	});

	const [selectedAllTags, setSelectedAllTags] = useState({
		tags_without_values: true,
		tags_with_values: true,
		other_params: true,
	});

	useEffect(() => {
		if (tags) {
			// Инициализация выбранных тегов по умолчанию
			const initialTags = {
				tags_without_values: tags.tags_without_values.map((tag) => tag.key),
				tags_with_values: tags.tags_with_values.map((tag) => tag.key),
				other_params: tags.other_params.map((tag) => tag.key),
			};
			setSelectedTags(initialTags);
		}
	}, [tags]);

	useEffect(() => {
		if (tags) {
			let updatedselectedAllTags = selectedAllTags;
			for (let item in selectedTags) {
				if (selectedTags[item].length === tags[item as keyof typeof selectedAllTags].length) {
					updatedselectedAllTags = {
						...updatedselectedAllTags,
						[item]: true,
					};
				} else {
					updatedselectedAllTags = {
						...updatedselectedAllTags,
						[item]: false,
					};
				}
			}
			setSelectedAllTags(updatedselectedAllTags);
		}
	}, [selectedTags]);

	const handleCheckboxChange = (group: string, key: string) => {
		setSelectedTags((prevSelectedTags) =>
			prevSelectedTags[group].includes(key)
				? {
						...prevSelectedTags,
						[group]: prevSelectedTags[group].filter((tagKey) => tagKey !== key),
				  }
				: { ...prevSelectedTags, [group]: [...prevSelectedTags[group], key] },
		);
	};

	const handleExport = () => {
		const allTags: string[] = [];
		for (let item in selectedTags) {
			allTags.push(...selectedTags[item]);
		}
		onIconClick(actionName, allTags, undefined, engine);
	};

	const selectAllTags = (tagGroup: keyof typeof selectedAllTags, value: boolean) => {
		setSelectedAllTags({
			...selectedAllTags,
			[tagGroup]: value,
		});

		if (tags) {
			setSelectedTags({
				...selectedTags,
				[tagGroup]: value ? tags[tagGroup].map((tag) => tag.key) : [],
			});
		}
	};

	const commonLabelStyle = {
		color: '#2F3747',
		fontFamily: 'Inter',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '22px',
	};

	if (!tags) {
		return (
			<div className={cls.preloader}>
				<CircularPreloader />
			</div>
		);
	}

	return (
		<div className={cls.root}>
			{tags?.tags_without_values && tags?.tags_without_values?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('tagsWithoutValues', lang)}</div>
					<div className={cls.tagsContainer}>
						<CustomCheckbox
							key={'allTagsWithoutValues'}
							testid={'allTagsWithoutValues'}
							className={cls.checkbox}
							checked={selectedAllTags.tags_without_values}
							onChange={(e) => selectAllTags('tags_without_values', e.target.checked)}
							disabled={false}
							label={translate('selectAllOptions', lang)}
							labelStyle={commonLabelStyle}
						/>
						{tags?.tags_without_values.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.tags_without_values.includes(tag.key)}
								onChange={() => handleCheckboxChange('tags_without_values', tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
					<span className={cls.separator} />
				</div>
			)}

			{tags?.tags_with_values && tags?.tags_with_values?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('tagsWithValues', lang)}</div>
					<div className={cls.tagsContainer}>
						<CustomCheckbox
							key={'allTagsWithValues'}
							testid={'allTagsWithValues'}
							className={cls.checkbox}
							checked={selectedAllTags.tags_with_values}
							onChange={(e) => selectAllTags('tags_with_values', e.target.checked)}
							disabled={false}
							label={translate('selectAllOptions', lang)}
							labelStyle={commonLabelStyle}
						/>
						{tags?.tags_with_values.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.tags_with_values.includes(tag.key)}
								onChange={() => handleCheckboxChange('tags_with_values', tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
					<span className={cls.separator} />
				</div>
			)}

			{tags?.other_params && tags?.other_params?.length > 0 && (
				<div className={cls.section}>
					<div className={cls.title}>{translate('otherParams', lang)}</div>
					<div className={cls.tagsContainer}>
						<CustomCheckbox
							key={'allTagsOtherParams'}
							testid={'allTagsOtherParams'}
							className={cls.checkbox}
							checked={selectedAllTags.other_params}
							onChange={(e) => selectAllTags('other_params', e.target.checked)}
							disabled={false}
							label={translate('selectAllOptions', lang)}
							labelStyle={commonLabelStyle}
						/>
						{tags?.other_params.map((tag) => (
							<CustomCheckbox
								key={tag.key}
								testid={tag.key}
								className={cls.checkbox}
								checked={selectedTags.other_params.includes(tag.key)}
								onChange={() => handleCheckboxChange('other_params', tag.key)}
								disabled={false}
								label={tag.title}
								labelStyle={commonLabelStyle}
							/>
						))}
					</div>
				</div>
			)}

			<div className={cls.buttonsBlock}>
				<UniversalButton
					text={translate('cancelButton', lang)}
					handleClick={toggleSttExportModal}
					buttonStyle="ghost"
				/>
				<UniversalButton text={translate('export', lang)} handleClick={handleExport} buttonStyle="filled" />
			</div>
		</div>
	);
};

export default ExportModalInner;
