import { createApi } from '@reduxjs/toolkit/query/react';
import {
	GPTAnswers,
	GptEngine,
	GPTOptionParams,
	GPTOptions,
	IGptOptions,
	IGPTRule,
	IGPTRuleExtended,
	RuleForCallResponse,
} from 'store/gpt/types';
import { IGPTQuestion } from '../widgets/GPTSettings/types';
import { getAnswersFromRuleResponse, getEngineOptions, getOnlyAnswersFromRuleResponse } from './utils/gpt';
import { getAllSearchCriterias } from '../store/search/search.slice';
import { baseQueryWithReauth } from 'api/api.config';

interface IUpdateGPTRuleById {
	id: string;
	body: Partial<IGPTRuleExtended>;
}

interface ITagForGpt {
	tagName: string;
	text?: string;
	choices?: string;
}

interface IGPTContext {
	gpt_rule_id: string;
	questions_titles: string[];
}

interface IRunRuleBody {
	gptOptions: IGptOptions;
	assistantText?: string;
	questions?: string[];
	tags?: ITagForGpt[];
	context?: IGPTContext;
	promptOptions: {
		addNowTime: boolean;
		addCallTime: boolean;
		addFragmentTime: boolean;
		isFree?: boolean;
	};
}

export interface GptEngineParams {
	currentGptEngine: GptEngine;
	models: string[];
	defaultModel: string;
	additionalParams: Record<string, GPTOptionParams>;
}

export const gptAPI = createApi({
	reducerPath: 'gptApi',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['gptOptions'],
	refetchOnMountOrArgChange: true,
	endpoints: (builder) => ({
		// queries
		getGPTRules: builder.query<IGPTRule[], void>({
			query: () => ({
				url: '/gpt/',
			}),
		}),
		getGPTRulesByRulesOwner: builder.query<IGPTRule[], string>({
			query: (id) => ({
				url: `/gpt/?rule_owner=${id}`,
			}),
		}),
		getGPTRuleById: builder.query<IGPTRuleExtended, string>({
			query: (id) => ({
				url: `/gpt/${id}`,
			}),
			async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
				const { data } = await queryFulfilled;
				// @ts-ignore
				if (data?.searchFilter?.items?.length && !getState().search.allCriterias.length) {
					await dispatch(getAllSearchCriterias());
				}
			},
		}),
		getGPTEngines: builder.query<GptEngine[], void>({
			query: () => ({
				url: '/gpt/gpt_engine_selectors',
			}),
		}),
		getGPTOptions: builder.query<GptEngineParams, GptEngine>({
			query: (engine) => ({
				url: `/gpt/${engine}/gpt_options`,
			}),
			transformResponse: (response: GPTOptions, meta, arg) => getEngineOptions(response, arg),
		}),
		// mutations
		// создание нового правила
		createGPTRule: builder.mutation<string, IGPTRuleExtended>({
			query: (body) => ({
				url: '/gpt/',
				method: 'POST',
				body,
			}),
			transformErrorResponse: (response: { status: string | number }) => response.status,
		}),
		// сохранение изменений существующего правила
		updateGPTRuleById: builder.mutation<void, IUpdateGPTRuleById>({
			query: ({ id, body }) => ({
				url: `/gpt/${id}`,
				method: 'PATCH',
				body,
			}),
		}),
		// удаление правила по id
		deleteGPTRuleById: builder.mutation<void, string>({
			query: (id) => ({
				url: `/gpt/${id}`,
				method: 'DELETE',
			}),
		}),
		// Клонирование правил
		cloneGPTRule: builder.mutation<void, string>({
			query: (id) => ({
				url: `/gpt/${id}/clone`,
				method: 'POST',
			}),
		}),
		// получение ответа на применения правила/произвольного запроса для звонка
		runGPTRuleForCall: builder.mutation<
			GPTAnswers[],
			{ callId: string; body: IRunRuleBody; type: 'rule' | 'query'; questions?: IGPTQuestion[] }
		>({
			query: ({ callId, body }) => ({
				url: `/gpt/gpt_request/${callId}/request`,
				method: 'POST',
				body,
			}),
			transformResponse: (response: RuleForCallResponse, meta, arg) => {
				if (arg.type === 'query') return getOnlyAnswersFromRuleResponse(response.responseData.answers);

				return getAnswersFromRuleResponse(
					response.responseData.answers,
					response.responseData.tags,
					arg?.questions,
				);
			},
			transformErrorResponse: (response: { status: string | number }) => response.status,
		}),
	}),
});
