import { manualTaggingAPI } from 'services/manualTaggingService';
import { useHandleErrors } from 'services/utils/useHandleErrors';

export const useManualTaggingService = (callId: string) => {
	const [
		addManualTag,
		{
			isError: isErrorAddManual,
			isLoading: isLoadingAddManual,
			isSuccess: isSuccessAddManual,
			error: addManualError,
		},
	] = manualTaggingAPI.useAddManualTagMutation();
	const [
		deleteManualTag,
		{
			isError: isErrorDeleteManual,
			isLoading: isLoadingDeleteManual,
			isSuccess: isSuccessDeleteManual,
			error: deleteManualError,
		},
	] = manualTaggingAPI.useDeleteManualTagMutation();

	const [
		restoreHiddenTag,
		{
			isError: isErrorRestoreTag,
			isLoading: isLoadingRestoreTag,
			isSuccess: isSuccessRestoreTag,
			error: restoreHiddenTagError,
		},
	] = manualTaggingAPI.useRestoreHiddenTagMutation();

	const { data: manualTags, refetch, error: manualTagsError } = manualTaggingAPI.useGetManualTagsQuery(null);

	const {
		refetch: refetchHiddenTags,
		data: hiddenTags,
		isLoading: isLoadingHiddenTags,
		isError: isErrorHiddenTags,
		error: hiddenTagsError,
	} = manualTaggingAPI.useGetHiddenTagsQuery(callId);

	useHandleErrors({
		errors: [addManualError, deleteManualError, restoreHiddenTagError, manualTagsError, hiddenTagsError],
	});

	return {
		data: {
			isLoadingAddManual,
			isErrorAddManual,
			isSuccessAddManual,
			tagsListForSelect: manualTags,

			isErrorDeleteManual,
			isLoadingDeleteManual,
			isSuccessDeleteManual,

			isErrorRestoreTag,
			isLoadingRestoreTag,
			isSuccessRestoreTag,

			hiddenTags,
			isLoadingHiddenTags,
			isErrorHiddenTags,
		},
		methods: {
			// ручные теги
			addManualTag,
			deleteManualTag,
			updateManualTags: refetch,

			// удаленные теги
			getHiddenTags: manualTaggingAPI.useGetHiddenTagsQuery,
			restoreHiddenTag,
			refetchHiddenTags,
		},
	};
};
