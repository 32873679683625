import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getCallStt, getEngineSTT, getTokenForCallAudio } from 'store/calls/calls.slice';
import { CallInfoType, CallSttFragmentType, CallSttType, CallTagType, CallType } from 'store/calls/calls.types';
import { AudioPlayer } from 'components/common';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { callsActions } from 'store/calls';
import { selectCurrentCall } from 'store/calls/selectors';
import { API_URL } from 'config';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { UserType } from 'store/user/user.types';
import { isBoolean } from '@craco/craco/dist/lib/utils';
import { useDialogItemsSelector } from './useDialogItemsSelector';
import { ISearchedWord } from '../components/TagsBlock/TagsBlock';
import CallBodyDialog from './CallBodyDialog/CallBodyDialog';
import DictionaryStep from './FastDictAddModalWindow/DictionaryStep';
import CallBodyCallParams from './CallBodyCallParams/CallBodyCallParams';
import { buildFragmentDictionary, buildStringByFragmentDictionary, FragmentDictionary } from '../helpers/fragments';
import useCallTranslation from '../../Call/hooks/useCallTranslation';
import GPTCallComments from '../../../widgets/GPTCallComments/GPTCallComments';
import { selectCallsCheckLists } from 'store/checkLists/selectors';
import { useStyles } from './CallBody.jss';
import { TagGroup } from 'widgets/ManualTagging/types';
import CallBodyEngines from './CallBodyEngines/CallBodyEngines';
import { translate } from 'localizations';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { useSnackbar } from 'notistack';

type CallBodyPropsType = {
	callInfo: CallInfoType;
	fragments?: CallTagType[];
	currentFragment: CallTagType | ISearchedWord | null;
	audioDivRef: React.RefObject<HTMLInputElement>;
	tagsDivRef: React.RefObject<HTMLInputElement>;
	paramsDivRef: React.RefObject<HTMLInputElement>;
	solo?: boolean;
	isCheckListsLoading: boolean;
	isAuth: boolean | 'loading';
	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	userInfo: UserType | null;
	currentPage: number | undefined;
	showParams?: boolean;
	hasAccessToTags: boolean;
	deleteTag?: (id: string) => Promise<void>;
	clickedTagId: string | null;
	clickedTagGroup: TagGroup | null;
	handleClickTagSettings: (id: string | null, tagGroup: TagGroup | null) => void;
	lang: string;
};

const CallBody: FC<CallBodyPropsType> = memo(
	({
		callInfo,
		fragments,
		currentFragment,
		audioDivRef,
		tagsDivRef,
		paramsDivRef,
		solo,
		isCheckListsLoading,
		isAuth,
		updateCheckList,
		userInfo,
		currentPage,
		showParams = true,
		hasAccessToTags,
		deleteTag,
		clickedTagGroup,
		clickedTagId,
		handleClickTagSettings,
		lang,
	}) => {
		const dispatch = useAppDispatch();
		const classes = useStyles();
		const history = useHistory();
		const { enqueueSnackbar } = useSnackbar();

		const checkLists = useAppSelector(selectCallsCheckLists);
		const currentCall = useAppSelector(selectCurrentCall);
		const callId = callInfo.id.toUpperCase();
		const languagesList = useAppSelector((state) => state.lang.allLanguages);
		const callTranslated = useAppSelector((state) => state.calls.callTranslated);
		const isCallLoading = useAppSelector((state) => state.calls.isCallLoading);

		const firstRenderFunc = useCallback(async () => {
			const searchParamsObject = queryString.parse(history.location.search);
			const token = searchParamsObject.token;
			dispatch(
				callsActions.setCurrentCall({
					id: callInfo.id,
					info: callInfo,
					stt: null,
					audio: null,
					token: null,
				}),
			);
			dispatch(getTokenForCallAudio({ id: callInfo.id, token }));
			dispatch(getCallStt({ id: callInfo.id, token }));
		}, [history.location.search, callInfo.id, callInfo]);

		useEffect(() => {
			firstRenderFunc();
		}, [firstRenderFunc]);

		const { getTranslate } = useCallTranslation();

		const prevIndex = useRef<string[] | undefined>(undefined);
		const currentIndex = useRef<string[] | undefined>(undefined);
		const audioPlayerRef = useRef<any>(currentCall ? currentCall.audio : '');

		const getIndices = (array: Array<any>, necessaryElements: any) => {
			const indices: number[] = [];
			for (let i = 0; i < necessaryElements.length; i++) {
				indices.push(array.indexOf(necessaryElements[i]));
			}
			return indices;
		};

		const [commentsKey, setCommentsKey] = useState(Date.now());

		useEffect(() => {
			setCommentsKey(Date.now());
		}, [currentCall && currentCall?.info?.comments]);

		// Возвращает строку с индексом фрагмента/фразы и слова.
		// eslint-disable-next-line consistent-return
		function findWordIndexes(phrases: CallSttFragmentType[] | undefined, currentTime: number) {
			if (phrases) {
				const currentFragments = phrases.filter(
					(phrase) => phrase.begin <= currentTime && currentTime <= phrase.end,
				);
				const currentFragmentsIndices = getIndices(phrases, currentFragments);

				if (currentFragmentsIndices.length < 1 || currentFragmentsIndices.some((index) => index < 0)) {
					return undefined;
				}

				const indices = [];
				for (let i = 0; i < currentFragments.length; i++) {
					const currentWords = currentFragments[i].words.filter(
						(word: any) => word.begin <= currentTime && currentTime <= word.end,
					);
					const currentWordsIndices = getIndices(currentFragments[i].words, currentWords);

					for (let j = 0; j < currentWords.length; j++) {
						indices.push(`${callId}-${currentFragmentsIndices[i]}-${currentWordsIndices[j]}`);
					}
				}
				if (indices.length < 1) {
					return undefined;
				}
				return indices;
			}
		} // return [1-4, 2-8]

		const onListen = (eventCurrentTime: any) => {
			let currentTimeLocal;
			if (eventCurrentTime.target) {
				currentTimeLocal = eventCurrentTime.target.currentTime * 1000;
			} else {
				currentTimeLocal = eventCurrentTime * 1000;
			}
			if (currentCall && currentCall.stt) {
				const indices = findWordIndexes(currentCall.stt.fragments, currentTimeLocal);
				if (indices && JSON.stringify(indices) !== JSON.stringify(prevIndex.current)) {
					let activeWord = null;
					for (let i = 0; i < indices.length; i++) {
						activeWord = document.getElementById(`${indices[i]}`);
						if (activeWord) {
							activeWord.classList.add(classes.isActive);
						}
					}
					if (prevIndex.current) {
						for (let i = 0; i < prevIndex.current.length; i++) {
							// убираем раскраску с предыдущего
							const removeElement = document.getElementById(`${prevIndex.current[i]}`);
							if (removeElement) {
								removeElement.classList.remove(classes.isActive);
							}
						}
					}
					// новоепредыдущее = текущее
					prevIndex.current = indices;
				}
				if (!indices) {
					// убираем раскраску с предыдущего
					const removeElement = document.getElementById(`${prevIndex.current}`);
					if (removeElement) {
						removeElement.classList.remove(classes.isActive);
					}
				}
				currentIndex.current = indices;
			}
		};

		const [isPlayed, setIsPlayed] = useState<boolean | null>(null);

		const handlePauseAudio = (event: KeyboardEvent) => {
			if (
				(event.key === ' ' || event.code === 'Space' || event.keyCode === 32) &&
				event.target === document.body
			) {
				event.preventDefault();
				if (audioPlayerRef.current.audioEl.current.currentTime === 0) {
					audioPlayerRef.current.audioEl.current.play();
					setIsPlayed(true);
				} else {
					if (isPlayed === null || isPlayed) {
						audioPlayerRef.current.audioEl.current.pause();
						setIsPlayed(false);
					}
					if (isBoolean(isPlayed) && !isPlayed) {
						audioPlayerRef.current.audioEl.current.play();
						setIsPlayed(true);
					}
				}
			}
		};

		useEffect(() => {
			document.addEventListener('keypress', handlePauseAudio);
			return () => {
				document.removeEventListener('keypress', handlePauseAudio);
			};
		}, [isPlayed]);

		const host = window.origin;

		const activeFragmentRef = useRef<any>(null);
		const prevActiveFragment = useRef<any>(null);

		const onFragmentClick = useCallback(
			(activeFragment: CallTagType | ISearchedWord | null) => {
				if (activeFragment) {
					activeFragmentRef.current = activeFragment;
					if (currentCall && currentCall.stt) {
						if (prevActiveFragment.current && prevActiveFragment.current !== activeFragment) {
							const removeFragment = document.getElementById(
								`${prevActiveFragment.current.fragment}-phrase`,
							);
							if (removeFragment) {
								removeFragment.classList.remove(classes.activeFragment);
							}
						}
						const activePhrase = currentCall.stt.fragments.find((fragment) => {
							if (activeFragment.fBegin) {
								// @ts-ignore
								return fragment.begin === activeFragment.fBegin && fragment.end === activeFragment.fEnd;
							} // @ts-ignore
							return fragment.begin === activeFragment.begin;
						});
						const allPhrases = document.getElementById(`${callInfo.id}`);
						let activePhraseHtmlEl;
						if (activePhrase) {
							activePhraseHtmlEl = document.getElementById(`${activePhrase.id}-phrase`);
						}
						if (activePhraseHtmlEl && allPhrases) {
							activePhraseHtmlEl.classList.add(classes.activeFragment);
							activePhraseHtmlEl.scrollIntoView({
								behavior: 'smooth',
								block: 'center',
							});
						}
						prevActiveFragment.current = activeFragment;
						if (
							audioPlayerRef.current &&
							audioPlayerRef.current.audioEl &&
							audioPlayerRef.current.audioEl.current
						) {
							if (activeFragment?.fBegin) {
								audioPlayerRef.current.audioEl.current.currentTime = activeFragment.fBegin / 1000;
							} else if (activeFragment.begin) {
								audioPlayerRef.current.audioEl.current.currentTime = activeFragment.begin / 1000;
							}

							audioPlayerRef.current.audioEl.current.play().catch((error: string) => {
								console.error('Error occurred while trying to play:', error);
							});
						}
					}
				}
			},
			[callInfo.id, classes.activeFragment, currentCall],
		);
		const user = useAppSelector((state) => state.user.user);
		const childUser = useAppSelector((state) => state.user.childUser);
		useEffect(() => {
			if (currentCall) {
				onFragmentClick(currentFragment);
			}
		}, [currentFragment, currentCall]);

		const accessRights = useAppSelector((state) => state.user.accessRights);

		const [isNotSkippedSelected, setIsNotSkippedSelected] = useState(false);

		const callDialogWrapperRef = useRef<HTMLDivElement>(null);

		const [selectionText, setSelectionText] = useState<string>('');
		const [isUserSelectText, setIsUserSelectText] = useState<boolean>(false);
		const [fragmentDictionary, setFragmentDictionary] = useState<FragmentDictionary>({});

		const onOutsideClick = useCallback(() => {
			if (!isUserSelectText) {
				setIsNotSkippedSelected(false);
			}
		}, [isUserSelectText]);

		useOnClickOutside(callDialogWrapperRef, onOutsideClick);

		const [open] = useState<boolean>(true);

		const [selector] = useDialogItemsSelector(callDialogWrapperRef, open);

		const getRowIndexElement = (node: any) => {
			while (node && node.nodeType !== Node.ELEMENT_NODE) {
				node = node.parentElement;
			}
			return node ? node.getAttribute('data-fragment-row-index') : null;
		};

		const adjustEndContainer = (node: any) => {
			// Если узел - элемент div, найти последний текстовый узел внутри него
			if (node && node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'DIV') {
				node = node.lastChild;
			}

			// Если узел - текстовый узел, убедиться, что он не пустой
			while (node && node.nodeType === Node.TEXT_NODE && node.textContent.trim() === '') {
				node = node.previousSibling || node.parentElement.previousSibling;
			}

			return node;
		};

		const handleSelectedText = (select: Selection | null, currentlySelected: string | undefined) => {
			if (accessRights?.dictionary) {
				const selection = select?.getRangeAt(0);
				const $startElement = selection?.startContainer.parentElement;
				let $endElement = selection?.endContainer;

				// Adjust the end element to skip empty text nodes or nodes with just spaces
				$endElement = adjustEndContainer($endElement);

				const strFragments = (currentCall && currentCall.stt && currentCall?.stt?.fragments) || [];
				const rowAttributeElement = 'data-fragment-row-index';

				const rowIndexStartElement = getRowIndexElement($startElement);
				const rowIndexEndElement = getRowIndexElement($endElement?.parentElement);

				// открывать модальное окно только при одном выделенном абзаце
				const isRowsIndexEqual = rowIndexStartElement === rowIndexEndElement;

				if (currentlySelected && isRowsIndexEqual) {
					const dictionary = buildFragmentDictionary($startElement, $endElement?.parentElement, strFragments);
					const text = buildStringByFragmentDictionary(dictionary, lang);
					setIsUserSelectText(true);
					setFragmentDictionary(dictionary);
					setIsNotSkippedSelected(true);
					setSelectionText(currentlySelected.split('\n').join('').trim());
				}

				selector();
			}
		};

		const onMouseUpFunction = useCallback(() => {
			if (window.getSelection()) {
				const select: Selection | null = window.getSelection();
				const currentlySelected: string | undefined = select?.toString();

				// check for rangeCount to prevent "Uncaught DOMException:
				// Failed to execute 'getRangeAt' on 'Selection': 0 is not a valid index"
				if (select && select.rangeCount > 0) handleSelectedText(select, currentlySelected);
			}
		}, [currentCall, lang, selector]);

		useEffect(() => {
			const subscribe = (event: ClipboardEvent): void => {
				if (isNotSkippedSelected) {
					event.preventDefault();
					const BUILD_WITH_PHRASE_DIRECTION = false;
					const text = buildStringByFragmentDictionary(fragmentDictionary, lang, BUILD_WITH_PHRASE_DIRECTION);

					event?.clipboardData?.setData('text/plain', text);
				}
			};

			document.addEventListener('copy', subscribe);

			return () => {
				document.removeEventListener('copy', subscribe);
			};
		}, [fragmentDictionary, isNotSkippedSelected, isUserSelectText, lang]);

		function clearDictionaryData(): void {
			setIsUserSelectText(false);
		}

		const hasAccessToAudio =
			(accessRights?.audio || accessRights?.admin || solo) && currentCall && currentCall.token;
		const hasAccessToSTT = accessRights?.stt || accessRights?.admin;
		const isAudio = callInfo.communicationType === 'call';

		const [loadingSTTByEngine, setLoadingSTTByEngine] = useState(false);
		const hasAccessToSTTByEngine = accessRights?.restt;

		const [langForTranslate, setLangForTranslate] = useState<{ value: string; label: string } | null>(null);

		const resetAndUpdateTranslate = (engine: string) => {
			dispatch(callsActions.setCallTranslated(null));
			if (langForTranslate) getTranslate(callId, langForTranslate?.value, engine);
		};

		const handleClickSetEngine = async (engine: string) => {
			if (engine) {
				setLoadingSTTByEngine(true);
				dispatch(callsActions.setCallLoading(true));
				const result = await dispatch(getEngineSTT({ call_id: callId, engine }));
				if (result?.meta?.requestStatus === 'rejected') {
					enqueueSnackbar(
						null,
						createSnackbarOptions({
							time: 2000,
							text: translate('status_failure', lang),
							type: 'error',
						}),
					);
				}
				// когда переключаемся между движками, (если текст был переведен), запрашиваем новый перевод
				if (result?.meta?.requestStatus === 'fulfilled' && callTranslated) {
					resetAndUpdateTranslate(engine);
				}
				setLoadingSTTByEngine(false);
				dispatch(callsActions.setCallLoading(false));
			}
		};

		return (
			<div className={classes.callBodyWrapper}>
				{hasAccessToAudio && isAudio && (
					<div ref={audioDivRef} className={classes.callBodyAudioBox}>
						<AudioPlayer
							onListen={onListen}
							callId={callId}
							callAudio={`${API_URL}call/audio/audio_by_token?token=${currentCall.token}`}
							audioPlayerRef={audioPlayerRef}
						/>
					</div>
				)}

				<div ref={tagsDivRef} className={classes.callBodyTags} />

				{solo && isAudio && hasAccessToSTTByEngine && (
					<CallBodyEngines
						lang={lang}
						allEngines={currentCall && (currentCall?.stt as CallSttType)?.all_engines}
						engine={currentCall && (currentCall?.stt as CallSttType)?.engine}
						handleClick={handleClickSetEngine}
						loading={loadingSTTByEngine}
					/>
				)}

				<CallBodyCallParams
					showParams={showParams}
					updateCheckList={updateCheckList}
					isAuth={isAuth}
					isCheckListsLoading={isCheckListsLoading}
					checkLists={checkLists}
					currentCall={currentCall}
					accessRights={accessRights}
					callInfo={callInfo}
					paramsDivRef={paramsDivRef}
					language={lang}
					classes={classes}
					userInfo={userInfo}
					isCallLoading={isCallLoading}
					currentPage={currentPage}
					tagGroup={clickedTagGroup}
					clickedTagId={clickedTagId}
					handleClickTagSettings={handleClickTagSettings}
					setLang={setLangForTranslate}
				/>
				{hasAccessToSTT && (
					<CallBodyDialog
						hasAccessToTags={hasAccessToTags}
						tagsAreActive={showParams}
						communicationType={callInfo.communicationType}
						innerRef={callDialogWrapperRef}
						accessRights={accessRights}
						callId={callId}
						currentCall={currentCall}
						audioPlayerRef={audioPlayerRef}
						fragments={fragments}
						onMouseUpFunction={onMouseUpFunction}
						classes={classes}
						solo={solo}
						language={lang}
						languagesList={languagesList}
						callTranslated={callTranslated}
						isCallLoading={isCallLoading}
						getTranslate={getTranslate}
						deleteTag={deleteTag}
						tagGroup={clickedTagGroup}
						clickedTagId={clickedTagId}
						handleClickTagSettings={handleClickTagSettings}
						lang={langForTranslate}
						setLang={setLangForTranslate}
					/>
				)}
				{currentCall && (
					<GPTCallComments
						lang={lang}
						key={commentsKey}
						callId={callId}
						expanded
						className={classes.callBodyCommentsWrapper}
						comments={currentCall && currentCall.info ? currentCall.info?.comments : []}
					/>
				)}
				{isUserSelectText && (
					<DictionaryStep
						open={isUserSelectText}
						fragmentDictionary={fragmentDictionary}
						selectionText={selectionText}
						closeDictionaryFunc={clearDictionaryData}
					/>
				)}
			</div>
		);
	},
);

export default memo(CallBody);
