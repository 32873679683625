import en from './en.json';
import ru from './ru.json';
import es from './es.json';
import pt from './pt.json';

// Определение типа для данных перевода
type TranslationData = Record<string, string>;

// Уточнение типа для langDataMap
const langDataMap: { [key: string]: TranslationData } = {
	en,
	ru,
	es,
	pt,
};

// Функция для определения формы слова на основе языка и количества
// Пример использования: SendReportModalInner.tsx
const getPluralForm = (language: string, count: number): string => {
	switch (language) {
		case 'ru':
			if (count % 10 === 1 && count % 100 !== 11) {
				return 'one';
			}
			if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
				return 'few';
			}
			return 'many';
		case 'en':
		case 'pt':
		case 'es':
			return count === 1 ? 'one' : 'other';
		default:
			return count === 1 ? 'one' : 'other';
	}
};

// Основная функция перевода с поддержкой склонений
export const translate = (key: string, language: string, count?: number): string => {
	const langData = langDataMap[language] || langDataMap['en'];

	if (count !== undefined) {
		const pluralForm = getPluralForm(language, count);
		const pluralKey = `${key}_${pluralForm}`;
		const translation =
			langData[pluralKey] || langData[key] || langDataMap['en'][pluralKey] || langDataMap['en'][key];
		return translation.replace('{{count}}', count.toString());
	}

	// Если count не указан, возвращаем просто перевод
	return langData[key] || langDataMap['en'][key] || key;
};
