import {
	dateTimeToStringForTable,
	dateTimeToStringForTableWithLocale,
} from 'utils/convertDateAndTime/dateTimeFormating';
import { getTime } from './GetTime';

export const dash = '—';

export const getCeilValueChecklistAnswerText = (value: any) => {
	let answerMaxPoint = 0;
	let answer;
	for (let key in value) {
		if (value[key] > answerMaxPoint) answer = key;
	}
	return answer;
};

// в additional params
export const getCeilValueAddtionaParams = (paramItem: any, translatedText: Record<string, string> | undefined) => {
	const type = paramItem.type;
	const value = paramItem.value;
	switch (type) {
		case 'view_tag_param':
			return value?.length ? value.join(', ') : dash;
		case 'view_avg_num_tag_param':
			return value ?? dash;
		case 'view_sum_num_tag_param':
			return value ?? dash;
		case 'view_comment_param':
			return value?.length ? value.join(', ') : dash;
		case 'view_checklist_answer_text_avg_param':
			return Object.keys(value).length > 0 ? getCeilValueChecklistAnswerText(value) : dash;
		case 'view_checklist_answer_avg_percent_param':
			return value ?? dash;
		case 'view_checklist_avg_percent_param':
			return value ?? dash;
		case 'view_percent_calculating_source_param':
			let arrayParams = [];
			for (let key in value) {
				arrayParams.push(`${translatedText?.[key]}: ${value[key]}%`);
			}
			return arrayParams.join(`, \n`);
		default:
			return value ?? dash;
	}
};

export const convertValue = (title: string, value: any, isSummaries = false) => {
	let convertedValue;

	if (isSummaries) {
		if (title === 'first_call_dt' || title === 'last_call_dt') {
			return value === null ? dash : dateTimeToStringForTableWithLocale(value);
		}
	}

	switch (title) {
		case 'calls_seconds':
			convertedValue = getTime(value);
			break;

		case 'percent_from_total_count':
			convertedValue = value;
			break;

		case 'percent_from_all_calls_col':
			convertedValue = value === null ? dash : value;
			break;

		case 'percent_from_all_calls_row':
			convertedValue = value === null ? dash : value;
			break;

		case 'percent_from_sum_calls_row':
			convertedValue = value === null ? dash : value;
			break;

		case 'percent_from_sum_calls_col':
			convertedValue = value === null ? dash : value;
			break;

		case 'checklist_avg':
			convertedValue = value ?? dash;
			break;

		case 'checklist_avg_percent':
			convertedValue = value > 0 || value === 0 ? value : dash;
			break;

		case 'client_talk_time':
			convertedValue = getTime(value);
			break;

		case 'client_talk_time_percentage':
			convertedValue = value;
			break;

		case 'first_call_dt':
			convertedValue = value === null ? dash : dateTimeToStringForTable(value);
			break;

		case 'last_call_dt':
			convertedValue = value === null ? dash : dateTimeToStringForTable(value);
			break;

		case 'operator_talk_time':
			convertedValue = getTime(value);
			break;

		case 'operator_talk_time_percentage':
			convertedValue = value;
			break;

		case 'silence_duration':
			convertedValue = getTime(value);
			break;

		case 'silence_duration_percentage':
			convertedValue = value;
			break;

		case 'client_phones':
			convertedValue = value.length ? value.join(', ') : dash;
			break;

		case 'operator_phones':
			convertedValue = value.length ? value.join(', ') : dash;
			break;

		case 'points':
			convertedValue = value ?? dash;
			break;

		case 'max_points':
			convertedValue = value ?? dash;
			break;

		case 'from_services':
			convertedValue = value.length ? value.join(', ') : dash;
			break;

		default:
			convertedValue = value;
			break;
	}
	return convertedValue;
};

export const convertDiffValue = (title: string, value: any) => {
	let convertedValue = '';

	switch (title) {
		case 'calls_count':
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;

		case 'calls_seconds':
			const diffValueMinutes = getTime(value);
			convertedValue = diffValueMinutes > '00:00:00' ? ` (+${diffValueMinutes})` : ` (${diffValueMinutes})`;
			break;

		case 'percent_from_total_count':
			convertedValue = value > 0 ? ` (+${value}%)` : ` (${value}%)`;
			break;

		case 'percent_from_all_calls_col':
			if (value === null) {
				convertedValue = ` (${dash})`;
			} else if (value > 0) {
				convertedValue = ` (+${value}%)`;
			} else {
				convertedValue = ` (${value}%)`;
			}
			break;

		case 'percent_from_all_calls_row':
			if (value === null) {
				convertedValue = ` (${dash})`;
			} else if (value > 0) {
				convertedValue = ` (+${value})`;
			} else {
				convertedValue = ` (${value})`;
			}
			break;

		case 'percent_from_sum_calls_row':
			if (value === null) {
				convertedValue = ` (${dash})`;
			} else if (value > 0) {
				convertedValue = ` (+${value}%)`;
			} else {
				convertedValue = ` (${value}%)`;
			}
			break;

		case 'percent_from_sum_calls_col':
			if (value === null) {
				convertedValue = ` (${dash})`;
			} else if (value > 0) {
				convertedValue = ` (+${value}%)`;
			} else {
				convertedValue = ` (${value}%)`;
			}
			break;

		case 'checklist_avg':
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;

		case 'checklist_avg_percent':
			if (value > 0) {
				convertedValue = ` (+${value}%)`;
			} else if (value === 0 || value < 0) {
				convertedValue = ` (${value})`;
			} else {
				convertedValue = ` (${dash})`;
			}
			break;

		case 'client_talk_time':
			const diffValueClient = getTime(value);
			convertedValue = diffValueClient > '00:00:00' ? ` (+${diffValueClient})` : ` (${diffValueClient})`;
			break;

		case 'client_talk_time_percentage':
			convertedValue = value > 0 ? ` (+${value}%)` : ` (${value}%)`;
			break;

		case 'first_call_dt':
			convertedValue = value === null ? ` (${dash})` : ` (${dateTimeToStringForTable(value)})`;
			break;

		case 'last_call_dt':
			convertedValue = value === null ? ` (${dash})` : ` (${dateTimeToStringForTable(value)})`;
			break;

		case 'operator_talk_time':
			const diffValueOperator = getTime(value);
			convertedValue = diffValueOperator > '00:00:00' ? ` (+${diffValueOperator})` : ` (${diffValueOperator})`;
			break;

		case 'operator_talk_time_percentage':
			convertedValue = value > 0 ? ` (+${value}%)` : ` (${value}%)`;
			break;

		case 'silence_duration':
			const diffValueSilence = getTime(value);
			convertedValue = diffValueSilence > '00:00:00' ? ` (+${diffValueSilence})` : ` (${diffValueSilence})`;
			break;

		case 'silence_duration_percentage':
			convertedValue = value > 0 ? ` (+${value}%)` : ` (${value}%)`;
			break;

		case 'client_phones':
			convertedValue = value.length ? ` (${value.join(', ')})` : ` (${dash})`;
			break;

		case 'operator_phones':
			convertedValue = value.length ? ` (${value.join(', ')})` : ` (${dash})`;
			break;

		case 'points':
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;

		case 'max_points':
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;

		case 'from_services':
			convertedValue = value.length ? ` (${value.join(', ')})` : ` (${dash})`;
			break;

		case 'tags_count':
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;

		default:
			convertedValue = value > 0 ? ` (+${value})` : ` (${value})`;
			break;
	}
	return convertedValue;
};
