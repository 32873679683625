import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { getAllSearchCriterias } from '../store/search/search.slice';
import { baseQueryWithReauth } from 'api/api.config';

export const notificationsAPI = createApi({
	reducerPath: 'notificationsAPI',
	baseQuery: baseQueryWithReauth,
	tagTypes: ['NotifyRule', 'PatchNotifyRule'],
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// Возвращает список правил оповещения пользователя
		getNotifyRules: build.query({
			query: () => ({
				url: `/notify_rules/`,
			}),
			providesTags: [{ type: 'NotifyRule' }, { type: 'PatchNotifyRule' }],
		}),
		fetchNotifyRules: build.query({
			query: (ruleOwner?: string) => {
				const queryParam = ruleOwner ? `?rule_owner=${encodeURIComponent(ruleOwner)}` : '';
				return `/notify_rules/${queryParam}`;
			},
			providesTags: [{ type: 'NotifyRule' }, { type: 'PatchNotifyRule' }],
		}),
		// Возвращает правило оповещения пользователя
		getNotifyRule: build.query({
			query: (notifyRuleId) => ({
				url: `/notify_rules/${notifyRuleId}`,
			}),
			async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
				const { data } = await queryFulfilled;
				// @ts-ignore
				if (data?.globalFilter?.items?.length && !getState()?.search?.allCriterias?.length) {
					await dispatch(getAllSearchCriterias());
				}
			},
			providesTags: [{ type: 'NotifyRule' }, { type: 'PatchNotifyRule' }],
		}),
		// Возвращает переменные с шаблоном для подстановки в текст сообщения
		getNotifyRuleVariables: build.query({
			query: () => ({
				url: `/notify_rules/notify_rule_variables`,
			}),
		}),
		// Возвращает переменные с шаблоном для подстановки в текст сообщения для конкретного звонка
		getCallNotifyRuleVariables: build.query({
			query: (callId) => ({
				url: `/notify_rules/call/${callId}/notify_rule_variables`,
			}),
		}),
		// Возвращает список доступных типов оповещений
		getAvailableDirections: build.query({
			query: () => ({
				url: `/notify_rules/available_directions`,
			}),
		}),
		// Возвращает описание полей которые мы можем заполнять в AmoCRM
		getAmoFillableCustomFields: build.query({
			query: () => ({
				url: `/notify_rules/amo_fillable_custom_fields`,
			}),
		}),
		// Возвращает описание полей которые мы можем заполнять в Bitrix
		getBitrixFillableCustomFields: build.query({
			query: () => ({
				url: `/notify_rules/bitrix_fillable_custom_fields`,
			}),
		}),
		// Тестирование шаблона письма
		testNotifyRuleTemplate: build.mutation({
			query: ({ callId, params }) => ({
				url: `/notify_rules/call/${callId}/test_notify_rule_template`,
				method: 'POST',
				body: params,
			}),
		}),
		// Создание правила оповещения
		createNotifyRule: build.mutation({
			query: ({ params }) => ({
				url: `/notify_rules/`,
				method: 'POST',
				body: params,
			}),
		}),
		// Выполнение действий с правилом оповещения
		notifyRuleActions: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}/action`,
				method: 'POST',
				body: params,
			}),
		}),
		// Обновление правила оповещения пользователя
		updateNotifyRule: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'PUT',
				body: params,
			}),
			invalidatesTags: [{ type: 'NotifyRule' }],
		}),
		// Частичное обновление правила оповещения пользователя
		patchNotifyRule: build.mutation({
			query: ({ notifyRuleId, params }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'PATCH',
				body: params,
			}),
			invalidatesTags: [{ type: 'PatchNotifyRule' }],
		}),
		// Удаление правила оповещения пользователя
		deleteUserNotify: build.mutation({
			query: ({ notifyRuleId }) => ({
				url: `/notify_rules/${notifyRuleId}`,
				method: 'DELETE',
			}),
		}),
		// Копирование правила
		cloneNotifyRule: build.mutation({
			query: ({ notifyRuleId }) => ({
				url: `/notify_rules/${notifyRuleId}/clone`,
				method: 'POST',
			}),
		}),
	}),
});
