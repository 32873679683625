import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';
import cn from 'classnames';
import { Tooltip } from 'components/common';
import s from './styles.module.scss';
import { TooltipPropsType } from 'components/common/Tooltip/Tooltip';

interface TextButtonWithTooltipProps {
	text: string;
	handleClick: any;
	loading: boolean;
	active: boolean;
	tooltip: string | null;

	disabled?: boolean;
	className?: string;
	testId?: string;
	buttonStyle?: 'outlined';
	background?: TooltipPropsType['background'];
	placement?: TooltipPropsType['placement'];
}

const TextButtonWithTooltip: FC<TextButtonWithTooltipProps> = ({
	text,
	handleClick,
	className,
	testId,
	buttonStyle = 'outlined',
	loading,
	disabled,
	active,
	tooltip,
	placement = 'bottom',
	background = 'white',
}) => {
	return (
		<Tooltip title={tooltip} placement={placement} background={background}>
			<Button
				className={cn(s.root, className, {
					[`${s.outlined}`]: buttonStyle === 'outlined',
					[`${s.active}`]: active,
					[`${s.disabled}`]: disabled,
					[`${s.loading}`]: loading,
				})}
				variant="outlined"
				startIcon={loading ? <CircularProgress size={16} /> : null}
				onClick={handleClick}
				data-testid={testId || ''}
				disabled={disabled}
			>
				{text}
			</Button>
		</Tooltip>
	);
};

export default TextButtonWithTooltip;
