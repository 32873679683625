import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useEffect, useCallback, useRef } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { RouteParams } from 'pages/Settings/types';
import { translate } from 'localizations';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';

type CustomErrorMessages = Record<number, string>;

interface UseHandleErrorsProps {
	errors: (FetchBaseQueryError | SerializedError | number | undefined)[];
	customMessages?: CustomErrorMessages;
}

export const useHandleErrors = ({ errors, customMessages }: UseHandleErrorsProps) => {
	const { lang } = useParams<RouteParams>();
	const { enqueueSnackbar } = useSnackbar();

	const errorQueue = useRef<number[]>([]);
	const isSnackbarOpen = useRef<boolean>(false);
	const processedErrors = useRef<Set<number>>(new Set());

	const processQueue = useCallback(() => {
		if (errorQueue.current.length > 0 && !isSnackbarOpen.current) {
			const errorStatus = errorQueue.current.shift();
			if (errorStatus !== undefined && !processedErrors.current.has(errorStatus)) {
				isSnackbarOpen.current = true;

				// Определяем сообщение
				let message: string = 'Unknown error';

				if (customMessages && customMessages[errorStatus]) {
					message = customMessages[errorStatus];
				} else {
					// Стандартные сообщения по кодам ошибок
					switch (errorStatus) {
						case 400:
							message = translate('error400', lang);
							break;
						case 401:
							message = translate('error401', lang);
							break;
						case 402:
							message = translate('error402', lang);
							break;
						case 403:
							message = translate('error403', lang);
							break;
						case 404:
							message = translate('error404', lang);
							break;
						case 409:
							message = translate('error409', lang);
							break;
						case 422:
							message = translate('error422', lang);
							break;
						case 500:
							message = translate('error500', lang);
							break;
						default:
							message = translate('unexpectedError', lang);
							break;
					}
				}

				enqueueSnackbar(message, {
					variant: 'error',
					autoHideDuration: 3000,
					onClose: () => {
						isSnackbarOpen.current = false;
						processQueue();
					},
				});

				// Добавляем статус ошибки в множество обработанных
				processedErrors.current.add(errorStatus);
			}
		}
	}, [enqueueSnackbar, lang, customMessages]);

	const handleErrors = useCallback(
		(errors: (FetchBaseQueryError | SerializedError | number | undefined)[]) => {
			errors.forEach((err) => {
				if (err === undefined) return;

				let errorStatus: number;

				if (
					typeof err === 'object' &&
					err !== null &&
					'status' in err &&
					typeof (err as FetchBaseQueryError).status === 'number'
				) {
					const status = (err as FetchBaseQueryError).status;
					errorStatus = typeof status === 'number' ? status : 0;
				} else if (
					typeof err === 'object' &&
					err !== null &&
					'code' in err &&
					typeof (err as SerializedError).code === 'string'
				) {
					const parsed = parseInt((err as SerializedError).code ?? '0', 10);
					errorStatus = isNaN(parsed) ? 0 : parsed;
				} else if (typeof err === 'number') {
					errorStatus = err;
				} else {
					errorStatus = 0;
				}

				// Проверяем, что ошибка ещё не обработана и не находится в очереди
				if (!processedErrors.current.has(errorStatus) && !errorQueue.current.includes(errorStatus)) {
					errorQueue.current.push(errorStatus);
				}
			});

			processQueue();
		},
		[processQueue],
	);

	useEffect(() => {
		handleErrors(errors);
	}, [errors, handleErrors]);

	return null;
};

// Пример использования
// useHandleErrors({
// 	errors: [notifyRulesError, getAvailableDirectionsError ],
// 	customMessages: {
// 		400: 'Пожалуйста, проверьте правильность введённых данных.',
// 		500: 'Произошла внутренняя ошибка сервера. Попробуйте позже.',
// 	},
// });
