import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export interface PatchGPTQuotasBody {
	key: string;
	day_limit: number;
}

export interface GetUserGPTQuotasResponse {
	key: string;
	label: string;
	day_limit: number;
	current_day_rest: number;
	currency: string;
	max_day_limit: number;
}

export const quotasServiceAPI = createApi({
	reducerPath: 'quotasServiceAPI',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		// Получение квот пользователя
		getUserGPTQuotas: build.query<GetUserGPTQuotasResponse[], { userId: string }>({
			query: ({ userId }) => ({
				url: `/user/${userId}/gpt_quotas`,
				method: 'GET',
			}),
		}),
		// Обновление квот пользователя
		patchUserGPTQuotas: build.mutation<void, { userId: string; body: PatchGPTQuotasBody[] }>({
			query: ({ userId, body }) => ({
				url: `/user/${userId}/gpt_quotas`,
				method: 'PATCH',
				body,
			}),
		}),
	}),
});
