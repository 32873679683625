/* мелкие функции для таблицы */

import { CallReportInfoType } from 'store/reports/reports.types';
import { dash } from './ConvertValuesInCeil';
import { AddParamI } from '../types/ReportsTypes';

/* заголовок у additional param в хэдере */
export const getTitleAdditionalParams = (paramItem: any) => {
	let addParam: AddParamI = {
		title: '',
		type: paramItem.type,
	};
	switch (paramItem.type) {
		case 'view_tag_param':
			addParam.title = paramItem.tag_name;
			break;
		case 'view_avg_num_tag_param':
			addParam.title = paramItem.tag_name;
			break;
		case 'view_sum_num_tag_param':
			addParam.title = paramItem.tag_name;
			break;
		case 'view_checklist_answer_avg_param':
			const checklist_title =
				paramItem.checklist_title === null || paramItem.checklist_title === ''
					? dash
					: paramItem.checklist_title;
			const question_text =
				paramItem.question_text === null || paramItem.question_text === '' ? dash : paramItem.question_text;

			addParam.title = `${checklist_title} / ${question_text}`;
			break;

		case 'view_checklist_answer_avg_percent_param':
			const checklist_title_percent =
				paramItem.checklist_title === null || paramItem.checklist_title === ''
					? dash
					: paramItem.checklist_title;
			const question_text_percent =
				paramItem.question_text === null || paramItem.question_text === '' ? dash : paramItem.question_text;

			addParam.title = `${checklist_title_percent} / ${question_text_percent}`;
			break;

		case 'view_checklist_avg_param':
			const checklistTitle =
				paramItem.checklist_title === null || paramItem.checklist_title === ''
					? dash
					: paramItem.checklist_title;

			addParam.title = `${checklistTitle}`;
			break;

		case 'view_checklist_avg_percent_param':
			const checklistTitlePercent =
				paramItem.checklist_title === null || paramItem.checklist_title === ''
					? dash
					: paramItem.checklist_title;

			addParam.title = `${checklistTitlePercent}`;
			break;

		case 'view_comment_param':
			const commentTitle =
				paramItem.comment_title === null || paramItem.comment_title === '' ? dash : paramItem.comment_title;
			addParam.title = `${commentTitle}`;

			break;

		case 'view_checklist_answer_text_avg_param':
			const checklistTitleAnswer =
				paramItem.checklist_title === null || paramItem.checklist_title === ''
					? dash
					: paramItem.checklist_title;
			const questionText =
				paramItem.question_text === null || paramItem.question_text === '' ? dash : paramItem.question_text;

			addParam.title = `${checklistTitleAnswer} / ${questionText}`;
			break;

		case 'view_percent_calculating_source_param':
			addParam.title = paramItem.title;
			break;

		default:
			break;
	}
	return addParam;
};

interface ObjectProps {
	[key: string]: string;
}

/* получение правильных заголовков */
export const convertHeaderName = (headerName: string, title: string, translatedText: ObjectProps) => {
	const headerNames = {
		calls_count: headerName,
		calls_seconds: headerName,
		percent_from_total_count: translatedText.percent_from_total_count,
		percent_from_all_calls_col: translatedText.percent_from_all_calls_col,
		percent_from_all_calls_row: translatedText.percent_from_all_calls_row,
		checklist_avg: headerName,
		checklist_avg_percent: headerName,

		client_talk_time: headerName,
		client_talk_time_percentage: `%`,

		operator_talk_time: translatedText.calls_seconds,
		operator_talk_time_percentage: `%`,

		first_call_dt: translatedText.calls_seconds,
		last_call_dt: translatedText.calls_seconds,

		silence_duration: translatedText.calls_seconds,
		silence_duration_percentage: `%`,

		tags_count: translatedText.tags_count,

		client_phones: translatedText.client_phones,
		operator_phones: translatedText.operator_phones,

		points: translatedText.points,
		max_points: translatedText.max_points,
		from_services: translatedText.from_services,

		row_sum: headerName,
		row_all: headerName,
		row_missed: headerName,
	};
	// @ts-ignore
	return headerNames[title];
};

/* подзаголовок */
export const convertHeaderSubSubTitle = (title: string | undefined, translatedText: ObjectProps) => {
	if (title) return translatedText[title];
	return '';
};

/* получение ключей для add params */
export const getObjectKeysAdditionalParams = (columnObject: CallReportInfoType) =>
	columnObject.additional_params?.map((paramItem) => getTitleAdditionalParams(paramItem));

/* провверка ячейки на нулевое value */
export const getCellClassNameHandler = (value: string | undefined): number => {
	const newValue = String(value);
	if (newValue[0] === '0') return 0;
	return 1;
};

/* корректное отображение даты и времени для ячеек */
export const dateFormattingOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};
