import { configureStore, combineReducers, Middleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { integrationsAPI } from 'services/IntegrationsService';
import { notificationsAPI } from 'services/NotificationsService';
import { tgChatsAPI } from 'services/tgChatsService';
import { gptAPI } from 'services/gptService';
import { gptCommentAPI } from 'services/gptCommentService';
import { consumptionHistoryApi } from 'services/ConsumptionHistoryService';
import calls from './calls';
import {
	langSlice,
	mrSlice,
	userSlice,
	searchSlice,
	tasksSlice,
	reportsSlice,
	integrations,
	notifications,
	gpt,
} from './index';
import checkLists from './checkLists';
import { setupAxiosInterceptors } from 'api/api.config';
import { manualTaggingAPI } from 'services/manualTaggingService';
import { userModulesAPI } from 'services/userModules';
import { sendReportNotifyAPI } from 'services/sendReportNotifyService';
import { billingAPI } from 'services/billingService';
import { quotasServiceAPI } from 'services/quotasService';

const rootReducer = combineReducers({
	lang: langSlice.reducer, // common*, общие данные приложения
	user: userSlice.reducer, // данные пользователя
	calls, // данные о звонках
	search: searchSlice.reducer, // данные поисков
	reports: reportsSlice.reducer, // данные отчетов
	tasks: tasksSlice.reducer, // данные задач
	mr: mrSlice.reducer, // данные тегов и словарей
	checkLists, // данные тегов и словарей
	integrations,
	notifications,
	[integrationsAPI.reducerPath]: integrationsAPI.reducer,
	[notificationsAPI.reducerPath]: notificationsAPI.reducer,
	[tgChatsAPI.reducerPath]: tgChatsAPI.reducer,
	gpt,
	[gptAPI.reducerPath]: gptAPI.reducer,
	[gptCommentAPI.reducerPath]: gptCommentAPI.reducer,
	[consumptionHistoryApi.reducerPath]: consumptionHistoryApi.reducer,
	[manualTaggingAPI.reducerPath]: manualTaggingAPI.reducer,
	[userModulesAPI.reducerPath]: userModulesAPI.reducer,
	[sendReportNotifyAPI.reducerPath]: sendReportNotifyAPI.reducer,
	[billingAPI.reducerPath]: billingAPI.reducer,
	[quotasServiceAPI.reducerPath]: quotasServiceAPI.reducer,
});

const middleware: Middleware[] = [
	integrationsAPI.middleware,
	notificationsAPI.middleware,
	tgChatsAPI.middleware,
	gptAPI.middleware,
	gptCommentAPI.middleware,
	consumptionHistoryApi.middleware,
	manualTaggingAPI.middleware,
	userModulesAPI.middleware,
	sendReportNotifyAPI.middleware,
	billingAPI.middleware,
	quotasServiceAPI.middleware,
];

if (process.env.NODE_ENV === 'development') {
	const loggerMiddleware = createLogger({
		collapsed: true,
		diff: true,
	});

	middleware.push(loggerMiddleware);
}

export const setupStore = () => {
	const store = configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
				immutableCheck: false,
			}).concat(middleware),
	});

	setupAxiosInterceptors(store.dispatch); // Настройка интерсепторов после создания store

	return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
