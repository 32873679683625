import React from 'react';
import { Typography } from '@mui/material';
import { CallInfoType } from 'store/calls/calls.types';
import { timeConverter } from 'utils/convertDateAndTime/dateTimeFormating';
import styles from './ClientBlock.module.scss';

interface IOwnProps {
	classes: Record<string, string>;
	clientPhone: CallInfoType['clientPhone'];
	duration: CallInfoType['duration'];
	hasAccessToPhone: boolean | undefined;
	hasAccessToDuration: boolean | undefined;
}

const ClientBlock: React.FC<IOwnProps> = ({
	classes,
	hasAccessToPhone,
	hasAccessToDuration,
	clientPhone,
	duration,
}) => (
	<div className={styles.employeeBlock}>
		{hasAccessToPhone && clientPhone && (
			<div className={styles.employeePhone}>
				<Typography className={classes.darkText}>{clientPhone}</Typography>
			</div>
		)}
		<div className={styles.employeeDuration}>
			{hasAccessToDuration && (
				<Typography className={classes.lightText}>{timeConverter(duration, true)}</Typography>
			)}
		</div>
	</div>
);

export default ClientBlock;
