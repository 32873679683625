import { useSnackbar } from 'notistack';
import { callsActions } from 'store/calls';
import { getTranslationWithLanguage } from 'store/calls/actions';
import { createSnackbarOptions } from 'components/common/Snackbar/Snackbar';
import { translate } from 'localizations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const useCallTranslation = () => {
	const dispatch = useAppDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { language } = useAppSelector((state) => state.lang);

	const getTranslate = async (callIdForTranslate: string, languageCode: string, engine: string) => {
		if (languageCode === 'original') {
			dispatch(callsActions.setCallTranslated(null));
		} else {
			dispatch(callsActions.setCallLoading(true));
			const translatedText = await dispatch(
				getTranslationWithLanguage({ callId: callIdForTranslate, languageCode, engine }),
			); // @ts-ignore
			if (!translatedText.payload) {
				enqueueSnackbar(
					null,
					createSnackbarOptions({
						type: 'error',
						time: 2000,
						text: `${translate('translateError', language)}`,
					}),
				);
			}
			dispatch(callsActions.setCallLoading(false));
		}
	};
	return { getTranslate }
};

export default useCallTranslation;
