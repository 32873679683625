import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
	callBodyWrapper: {
		minHeight: '339px',
		display: 'grid',
		// gridTemplateColumns: 'repeat(2, 1fr)',
		gridTemplateColumns: 'calc(100% - 288px) 288px',
		gridTemplateRows: '76px auto 1fr',
	},
	callBodyAudioBox: {
		position: 'sticky',
		top: '0px',
		height: 'auto',
		zIndex: 100,
		// gridColumnStart: 1,
		// gridColumnEnd: 3,
		// gridRow: 1,
		gridArea: '1 / 1 / 2 / 3',
	},
	callBodyTags: {
		maxHeight: '0px',
		position: 'sticky',
		zIndex: 99,
		backgroundColor: '#EEF2F6',
		gridColumn: 1,
		gridRow: 2,
	},
	callBodyCallParams: {
		// gridColumn: 2,
		gridArea: '2 / 2 / 4 / 3',
		backgroundColor: '#F8FAFC',
		marginLeft: '16px',
	},
	callBodyDialogWrapper: {
		// gridColumn: 1,
		// gridRowStart: 2,
		// gridRowEnd: 2,
		gridArea: '3 / 1 / 3 / 2',
		borderRadius: '10px',
		width: '100%',
		backgroundColor: '#fff',
		padding: '16px',
	},

	callBodyCommentsWrapper: {
		marginTop: '16px',
		gridArea: '3 / 1 / 4 / 2',
	},

	callBodyTagsArray: {
		overflowY: 'auto',
		height: '100%',
		maxHeight: '105px',
		'&::-webkit-scrollbar': {
			width: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#9298A1',
		},
	},
	cbDialog: {
		height: '100%',
	},
	cbDialogItems: {
		padding: '0 0 5px 0',
		// height: '800px',
		'&::-webkit-scrollbar': {
			width: '4px',
			height: '4px',
			backgroundColor: '#f1f1f1',
			outline: 'none',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#A3AEBE',
			height: '50px',
			borderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb:hover': {
			background: '#555',
		},
	},
	controlBlockButtonBox: {
		boxShadow: 'none !important',
		padding: '0 5px',
		marginLeft: '20px',
	},
	controlBlockButton: {
		border: 'none !important',
		transition: '0.4s !important',
		outline: 'none !important',
		height: '40px',
		fontSize: '14px !important',
		// @ts-ignore
		textTransform: 'none !important',
		color: '#738094 !important',
		backgroundColor: '#ffffff !important',
		'&.Mui-selected': {
			backgroundColor: '#D6D9DF !important',
			color: '#000 !important',
		},
	},
	isActive: {
		backgroundColor: '#CDD5DF',
		color: '#000',
	},
	typographyTitle: {
		color: '#738094 !important',
		fontWeight: '700 !important',
		minWidth: '115px !important',
		userSelect: 'none',
	},
	activeFragment: {
		backgroundColor: '#F5F5DC',
	},
	cdRightBlock: {
		overflow: 'hidden',
		position: 'sticky',
		right: '0',
		top: '-100px',
		zIndex: '100',
	},
	cbPgrase: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'start',
		userSelect: 'none',
	},
	cbPgraseDialod: {
		width: '80%',
		display: 'flex',
		alignItems: 'start',
		userSelect: 'none',
	},
	callTranslated: {
		margin: '3px 0',
		width: '50%',
		color: '#738094',
		userSelect: 'text',
		wordBreak: 'break-all',
	},
	cbFragments: {
		display: 'flex',
		justifyContent: 'end',
		flexWrap: 'wrap',
		userSelect: 'text', // none
		width: '20%',
		paddingLeft: '16px',
	},
	cbFragmentName: {
		backgroundColor: '#EFDBFF',
		color: '#531DAB',
		border: '1px solid #B37FEB',
		padding: '1px 8px',
		margin: '0 4px 4px 0',
		borderRadius: '5px',
		cursor: 'pointer',
		fontSize: '12px',
		lineHeight: '20px',
	},
	cbFragmentNameDisabled: {
		cursor: 'default',
	},
});
